import { Newtype } from 'newtype-ts';
import { addressSchema, nonEmptyStringSchema } from '@shared/schemas';
import z from 'zod';
import { FilterQueryParser, getEnumQuery, getSearchQuery, SearchFilter } from '@shared/modules/filter';
import { LocalDate } from '@shared/modules/dates';

export type CaseId = Newtype<{ readonly ID: unique symbol }, string> & string;
export type ProductId = Newtype<{ readonly ID: unique symbol }, number> & number;
export type ProductUpdateId = Newtype<{ readonly ID: unique symbol }, string> & string;

export enum CaseStatus {
  InProgress = 'in-progress',
  Terminated = 'terminated',
}

export const caseStatusLabel: Record<CaseStatus, string> = {
  [CaseStatus.InProgress]: 'En cours',
  [CaseStatus.Terminated]: 'Résilié',
};

export enum CaseType {
  New = 'NEW',
  Existing = 'EXISTING',
  Concurrent = 'CONCURRENT',
  HD = 'HD',
}

export const caseTypeLabel: Record<CaseType, string> = {
  [CaseType.New]: 'Nouvelle installation',
  [CaseType.Existing]: 'Chantier historique (protection classique)',
  [CaseType.Concurrent]: 'Chantier concurrent',
  [CaseType.HD]: 'Chantier HD',
};

export interface CasesRangeFilter extends SearchFilter {
  status: CaseStatus | null;
}

export const casesFilterParser: FilterQueryParser<CasesRangeFilter> = query => ({
  search: getSearchQuery(query),
  status: getEnumQuery(query, CaseStatus, 'status'),
});

export interface CaseAddress {
  city: string;
  postalCode: string;
  firstLine: string;
}

export interface Case {
  id: CaseId;
  name: string;
  customerName: string;
  address: CaseAddress;
  status: CaseStatus;
}

const caseZoneInfoSchema = z.object({
  buildingSurface: z.number(),
  landSurface: z.number(),
});

const caseProductsSchema = z.object({
  productId: z.number().transform(id => id as ProductId),
  label: z.string().nullish(),
  qte: z.number().nullish(),
});

const caseProductsUpdateSchema = caseProductsSchema.omit({ productId: true }).extend({
  id: z.string().transform(id => id as ProductUpdateId),
});

export const createCaseSchema = z.object({
  name: nonEmptyStringSchema,
  customerName: nonEmptyStringSchema,
  address: addressSchema,
  installDate: z
    .date()
    .nullable()
    .refine(val => val != null),
  type: z
    .nativeEnum(CaseType)
    .nullable()
    .refine(val => val != null),
  zoneInfo: caseZoneInfoSchema.nullable(),
  products: z.array(caseProductsSchema),
});

export type CreateCaseFormBody = z.infer<typeof createCaseSchema>;

export const createCaseSchemaParams = createCaseSchema.omit({ installDate: true }).extend({
  installDate: z.string().transform(installDate => installDate as LocalDate),
});

export type CreateCaseParams = z.infer<typeof createCaseSchemaParams>;

export interface DetailCaseZoneInfo {
  buildingSurface: number;
  landSurface: number;
}

export interface DetailCaseProducts {
  id: ProductUpdateId;
  label: string;
  qte: number;
}

export interface DetailCase extends Omit<CreateCaseParams, 'installDate' | 'products' | 'zoneInfo'> {
  id: CaseId;
  installDate: LocalDate;
  terminationDate: LocalDate;
  zoneInfo: DetailCaseZoneInfo;
  products: Array<DetailCaseProducts>;
}

export const updateCaseSchema = z.object({
  name: nonEmptyStringSchema,
  customerName: nonEmptyStringSchema,
  address: addressSchema,
  installDate: z.date(),
  terminationDate: z.date().nullable(),
  zoneInfo: caseZoneInfoSchema,
  products: z.array(caseProductsUpdateSchema),
});

export type UpdateCaseFormBody = z.infer<typeof updateCaseSchema>;

export const updateCaseSchemaParams = z.object({
  name: nonEmptyStringSchema,
  customerName: nonEmptyStringSchema,
  address: addressSchema,
  installDate: z.string().transform(date => date as LocalDate),
  terminationDate: z
    .string()
    .transform(date => date as LocalDate)
    .nullable(),
  zoneInfo: caseZoneInfoSchema,
  products: z.array(caseProductsUpdateSchema),
});

export type UpdateCaseParams = z.infer<typeof updateCaseSchemaParams>;

export interface CaseProducts {
  id: ProductId;
  label: string;
}
