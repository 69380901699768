import React, { FC } from 'react';
import * as StocksService from './service';
import { pipe } from 'fp-ts/function';
import { defineLoader, httpTaskToResponseTask, useLoader } from '@core/router/loader';
import { defineAction, useAction } from '@core/router/action';
import { StockDeclareFormBody, stocksDeclareFormBodySchema, stocksDeclareSchema } from '@modules/stocks/model';
import { EnhancedForm, mapToEnhancedFormSubmitResult, useEnhancedForm } from '@shared/modules/form';
import { zodResolver } from '@hookform/resolvers/zod';
import { defineRoute } from '@core/router';
import Page, { PageProps } from '@layout/page/Page';
import StocksForm from '@modules/stocks/components/form/StocksForm';
import { useProfile } from '@modules/auth/profile-loader';
import { Navigate } from 'react-router-dom';

const loader = defineLoader({
  handler: () => httpTaskToResponseTask(StocksService.getUndeclaredStock()),
});

const actions = {
  declare: defineAction({
    type: 'declare',
    payload: stocksDeclareSchema,
    handler: ({ payload }) => StocksService.declareStocks(payload),
    flashOptions: { success: () => 'Déclaration envoyée' },
  }),
};

const StocksPageForm: FC = () => {
  const undeclaredStocks = useLoader<typeof loader>();

  const [loading, declareStock] = useAction(actions.declare);

  const { formRef, form, handleFormSubmit } = useEnhancedForm({
    resolver: zodResolver(stocksDeclareFormBodySchema),
    defaultValues: {
      products: undeclaredStocks,
    },
  });

  const pageProps: PageProps = {
    title: 'Déclaration de stock restant',
  };

  const handleDeclareStock = (params: StockDeclareFormBody) => {
    return pipe(
      () => declareStock(params.products),
      mapToEnhancedFormSubmitResult(() => '/'),
    );
  };

  return (
    <Page {...pageProps}>
      <EnhancedForm ref={formRef} form={form} onSubmit={handleDeclareStock}>
        <StocksForm loading={loading} onSubmit={handleFormSubmit} />
      </EnhancedForm>
    </Page>
  );
};

const StocksPage: FC = () => {
  const profile = useProfile();

  if (profile.mustDeclareStock) return <StocksPageForm />;
  else return <Navigate to="/" />;
};

const stocksRoute = defineRoute({
  component: StocksPage,
  loader,
  actions,
});

export default stocksRoute;
