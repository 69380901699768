import React, { FC, PropsWithChildren } from 'react';

import { ModalContent, SharedButton } from '@styles/shared';
import { Group, Modal, Text } from '@mantine/core';

interface FormPreventLeaveModalProps {
  open: boolean;
  submitting: boolean;
  onClose: () => void;
  onLeave: () => void;
  onSubmitAndLeave: () => void;
}

const FormPreventLeaveModal: FC<PropsWithChildren<FormPreventLeaveModalProps>> = ({
  open,
  submitting,
  onClose,
  onLeave,
  onSubmitAndLeave,
}) => {
  return (
    <Modal
      opened={open}
      onClose={onClose}
      centered
      size={500}
      title={
        <Text size="lg" weight="600">
          Modifications en cours
        </Text>
      }
    >
      <ModalContent>
        <Text size="sm">
          Si vous quittez maintenant cette page, vos modifications en cours ne seront pas enregistrées.
        </Text>
      </ModalContent>

      <Group position="right">
        <SharedButton btnType="cancel" children="Quitter maintenant" onClick={onLeave} disabled={submitting} />
        <SharedButton
          btnType="save"
          children="Enregister et quitter"
          onClick={onSubmitAndLeave}
          loading={submitting}
          disabled={submitting}
        />
      </Group>
    </Modal>
  );
};

export default FormPreventLeaveModal;
