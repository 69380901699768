import React, { FC, PropsWithChildren } from 'react';
import { renderNullable } from '@shared/utils/render';

import * as Styled from './AuthLayout.styles';
import { Title } from '@mantine/core';
import sentriprime from '@assets/images/sentriprime.svg';

interface AuthLayoutProps {
  title?: string;
}

const AuthLayout: FC<PropsWithChildren<AuthLayoutProps>> = ({ title, children }) => {
  return (
    <Styled.AuthLayoutContainer>
      <Styled.AuthLayoutImage src={sentriprime} alt="SentriPrime logo" />

      {renderNullable(title, title => (
        <Title order={1} color="white" mb="md">
          {title}
        </Title>
      ))}

      {children}
    </Styled.AuthLayoutContainer>
  );
};

export default AuthLayout;
