import React from 'react';
import { Route } from 'react-router-dom';
import { createRoute } from '@core/router';

import stocksRoute from '@modules/stocks/StocksPage';

const stocksRoutes = (
  <Route path="stocks">
    <Route index {...createRoute(stocksRoute)} />
  </Route>
);

export default stocksRoutes;
