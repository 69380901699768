import { Newtype } from 'newtype-ts';
import z from 'zod';

export type StockId = Newtype<{ readonly ID: unique symbol }, number> & number;

export const stocksDeclareSchema = z.array(
  z.object({
    id: z.number().transform(id => id as StockId),
    label: z.string(),
    quantity: z.number().nullable(),
  }),
);

export type StockDeclareParams = z.infer<typeof stocksDeclareSchema>;

export const stocksDeclareFormBodySchema = z.object({
  products: stocksDeclareSchema,
});

export type StockDeclareFormBody = z.infer<typeof stocksDeclareFormBodySchema>;
