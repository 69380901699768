import React from 'react';
import * as Styled from './Filters.styles';

import { Filter, FilterChildren, SearchFilter } from '@shared/modules/filter/model';
import { renderNullable } from '@shared/utils/render';
import SearchFilterComponent from './search/SearchFilter';
import { Paper } from '@mantine/core';
import { IconEraser } from '@tabler/icons-react';

interface FiltersProps<F extends Filter> {
  filter: F;
  search?: F extends SearchFilter ? boolean : never;
  onFilter: (filter: F) => void;
  clean?: boolean;
  onClean?: () => void;
  children?: FilterChildren<F>;
}

function Filters<F extends Filter>({ filter, search, onFilter, clean = false, onClean, children }: FiltersProps<F>) {
  const handleSearch = (search: string | null) => onFilter({ ...filter, search });

  return (
    <Paper p="md">
      <Styled.FiltersContainer>
        {renderNullable(children, children => children({ filter, onChange: onFilter }))}

        {search ? <SearchFilterComponent defaultValue={filter.search as any} onChange={handleSearch} /> : null}

        {clean ? (
          <Styled.FiltersErase onClick={onClean}>
            <IconEraser width={20} />
          </Styled.FiltersErase>
        ) : null}
      </Styled.FiltersContainer>
    </Paper>
  );
}

export default Filters;
