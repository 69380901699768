import React, { FC } from 'react';
import { Button, Group, Modal, Text } from '@mantine/core';
import { IconDeviceFloppy } from '@tabler/icons-react';

interface StocksModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: () => void;
}

const StocksModal: FC<StocksModalProps> = ({ isOpen, onClose, onSubmit }) => {
  const handleClick = () => {
    onSubmit();
    onClose();
  };

  return (
    <Modal opened={isOpen} onClose={onClose} title="Confirmer la déclaration de stock" centered>
      <Text size={14} pt="xs">
        Êtes-vous sûr de vouloir confirmer la déclaration de stock ?
      </Text>

      <Text size={14} pt="xs">
        Cette action est irréversible
      </Text>

      <Group position="right" pt="md">
        <Button color="gray" onClick={onClose}>
          Annuler
        </Button>

        <Button leftIcon={<IconDeviceFloppy />} onClick={handleClick}>
          Valider
        </Button>
      </Group>
    </Modal>
  );
};

export default StocksModal;
