import React, { FC } from 'react';
import { CaseImportError, caseImportErrorCodeLabel } from '@modules/import/model';
import { Group, List, Paper, Text } from '@mantine/core';

interface ImportFormErrorsProps {
  errors: Array<CaseImportError>;
}

const ImportFormErrors: FC<ImportFormErrorsProps> = ({ errors }) => {
  return (
    <Paper style={{ border: '2px solid red' }} mt="md" p="xl">
      <Text color="red" weight={700}>
        Nous avons détecté une ou plusieurs erreurs
      </Text>

      <Text>Veuillez corriger les erreurs suivantes et réimporter le fichier</Text>

      <List pl="xl" pt="xl">
        {errors.map((error, index) => (
          <List.Item key={index}>
            <Group>
              <Text weight={700}>
                Ligne {error.line}, Colonne {error.columnString}
              </Text>
              -<Text>{caseImportErrorCodeLabel[error.code]}</Text>
            </Group>
          </List.Item>
        ))}
      </List>
    </Paper>
  );
};

export default ImportFormErrors;
