import React, { FC } from 'react';
import { Anchor, Button, Group, Paper, Text } from '@mantine/core';
import { IconEye } from '@tabler/icons-react';

const ImportFormInfos: FC = () => {
  return (
    <>
      <Paper mt="md" p="xl">
        <Text weight={600}>1. Fichier modèle Excel</Text>

        <Text>
          Vous devez exporter, depuis votre outil de gestion, les informations de vos chantiers en respectant la
          structure du fichier en exemple.
        </Text>

        <Group pt="md">
          <Anchor href="/import/modele-import.xlsx" download>
            <Button type="button">
              <IconEye />
              <Text pl="xs">Regarder l'exemple</Text>
            </Button>
          </Anchor>
        </Group>
      </Paper>

      <Paper mt="md" p="xl">
        <Text weight={600}>2. Export de vos données</Text>

        <Text>
          Depuis votre outil de gestion, exportez les informations de vos chantiers en respectant la structure du
          fichier donné en exemple.
        </Text>
      </Paper>
    </>
  );
};

export default ImportFormInfos;
