import styled from '@emotion/styled';
import { Group } from '@mantine/core';

export const UpdateProfileFormEmailLabel = styled.div`
  display: flex;
`;

export const UpdateProfileFormEmail = styled(Group)`
  padding: 0 12px;
  display: grid;
  grid-template-columns: 1fr 24px;
  align-items: center;
  background-color: #f1f3f5;
  opacity: 0.6;
  border-radius: 4px;
  border: 1px solid #ced4da;
  cursor: pointer;
  height: 36px;

  > div {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export const UpdateProfileFormInfos = styled(Group)`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 16px;

  @media screen and (max-width: 600px) {
    grid-template-columns: 1fr;
  }
`;

export const UpdateProfileFormActions = styled.div`
  padding-top: 20px;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  > button {
    margin-left: 16px;
  }

  @media screen and (max-width: 760px) {
    flex-direction: column-reverse;
    justify-content: center;

    > button {
      margin-left: 0;
      margin-bottom: 10px;
    }
  }
`;
