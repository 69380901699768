import React, { FC, useState } from 'react';
import { useEnhancedFormContext } from '@shared/modules/form';
import { StockDeclareFormBody } from '@modules/stocks/model';
import { Button, NumberInput, Paper, Table, Text } from '@mantine/core';
import { Controller, useFieldArray } from 'react-hook-form';
import { IconArrowLeft, IconSend } from '@tabler/icons-react';
import { Link } from 'react-router-dom';
import styled from '@emotion/styled';
import StocksModal from '@modules/stocks/components/modal/StocksModal';

const StocksFormActions = styled.div`
  padding-top: 20px;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  > button:last-child {
    margin-left: 16px;
  }

  @media screen and (max-width: 760px) {
    flex-direction: column-reverse;
    justify-content: center;

    > button:last-child {
      margin-left: 0;
      margin-bottom: 10px;
    }
  }
`;

interface StocksFormProps {
  loading: boolean;
  onSubmit: () => void;
}

const StocksForm: FC<StocksFormProps> = ({ loading, onSubmit }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const {
    control,
    formState: { errors },
    handleSubmit,
  } = useEnhancedFormContext<StockDeclareFormBody>();

  const { fields } = useFieldArray({ control, name: 'products' });

  return (
    <form onSubmit={handleSubmit} noValidate>
      <Text pt="md">Merci de nous dire combien il vous reste de quantités pour les produits ci-dessous :</Text>

      <Paper p="md" mt="xl">
        <Table striped withBorder bg="white">
          <thead>
            <tr>
              <th>Produit</th>
              <th>Quantité</th>
            </tr>
          </thead>

          <tbody>
            {fields.map((product, index) => (
              <tr key={index}>
                <td>{product.label}</td>
                <td>
                  <Controller
                    control={control}
                    name={`products.${index}.quantity`}
                    render={({ field }) => (
                      <NumberInput
                        type="number"
                        placeholder="Quantité"
                        error={!!errors.products?.[index]?.quantity}
                        value={field.value ?? undefined}
                        onChange={field.onChange}
                        min={0}
                        hideControls
                      />
                    )}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Paper>

      <StocksFormActions>
        <Button color="gray" leftIcon={<IconArrowLeft />} component={Link} to="/">
          Annuler
        </Button>

        <Button leftIcon={<IconSend />} loading={loading} onClick={() => setIsOpen(true)}>
          Valider
        </Button>
      </StocksFormActions>

      <StocksModal isOpen={isOpen} onClose={() => setIsOpen(false)} onSubmit={onSubmit} />
    </form>
  );
};

export default StocksForm;
