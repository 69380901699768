import styled from '@emotion/styled';

export const FiltersContainer = styled.div`
  display: flex;
  align-items: center;

  @media screen and (max-width: 760px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const FiltersErase = styled.div`
  margin-left: 10px;
  padding: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  background-color: #cccccc;
  cursor: pointer;

  @media screen and (max-width: 760px) {
    margin-left: 0;
    margin-top: 10px;
  }
`;
