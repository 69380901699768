import React, { FC } from 'react';
import { UpdateEmailParams } from '@modules/auth/model';
import { Button, Group, Modal, TextInput } from '@mantine/core';
import { useEnhancedFormContext } from '@shared/modules/form';
import { IconDeviceFloppy } from '@tabler/icons-react';

interface UpdateProfileFormModalProps {
  isOpen: boolean;
  onClose: () => void;
  loadingEmail: boolean;
  onSubmitEmail: () => void;
}

const UpdateProfileFormModal: FC<UpdateProfileFormModalProps> = ({ isOpen, onClose, loadingEmail, onSubmitEmail }) => {
  const {
    register,
    formState: { errors, isValid },
    handleSubmit,
  } = useEnhancedFormContext<UpdateEmailParams>();

  const onSubmit = () => {
    onSubmitEmail();
    if (isValid) onClose();
  };

  return (
    <Modal opened={isOpen} onClose={onClose} title="Changer l'e-mail" centered>
      <form onSubmit={handleSubmit} noValidate>
        <TextInput
          type="email"
          label="E-mail"
          placeholder="Nouvel e-mail"
          required
          error={!!errors.email}
          {...register('email')}
        />

        <Group position="right" pt="md">
          <Button loading={loadingEmail} onClick={onSubmit}>
            <IconDeviceFloppy />
            Enregistrer
          </Button>
        </Group>
      </form>
    </Modal>
  );
};

export default UpdateProfileFormModal;
