import { MantineProvider } from '@mantine/core';
import { Notifications } from '@mantine/notifications';
import React, { FC, PropsWithChildren } from 'react';
import { MantineThemeOverride } from '@mantine/core';

const theme: MantineThemeOverride = {
  fontFamily: 'Open Sans, sans-serif',
  headings: {
    sizes: {
      h1: {
        fontSize: '26px',
      },
    },
  },
  components: {
    InputWrapper: {
      styles: {
        label: {
          fontWeight: 600,
          paddingBottom: 5,
        },
      },
    },
    Paper: {
      defaultProps: {
        withBorder: true,
        radius: 16,
      },
    },
    Modal: {
      styles: {
        root: {
          zIndex: 2000,
        },
      },
      defaultProps: theme => ({
        overlayColor: theme.colors.gray[2],
        overlayBlur: 2,
      }),
    },
  },
  colors: {
    green: [
      '#A2B897',
      '#90B080',
      '#7EAC69',
      '#6EAC50',
      '#60A63E',
      '#53A32D',
      '#4E8833',
      '#497336',
      '#446236',
      '#3F5434',
    ],
    yellow: [
      '#DED9BD',
      '#D7CF9F',
      '#D5C97E',
      '#DBC85A',
      '#E8CD30',
      '#FFDA00',
      '#CFB417',
      '#A99625',
      '#8D7F2D',
      '#766C31',
    ],
  },
  primaryShade: 6,
  primaryColor: 'green',
};
const MantineTheme: FC<PropsWithChildren> = ({ children }) => {
  return (
    <MantineProvider theme={theme} withNormalizeCSS withGlobalStyles>
      <Notifications position="top-right" />
      {children}
    </MantineProvider>
  );
};

export default MantineTheme;
