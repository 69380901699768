import React, { FC } from 'react';
import { useProfile } from '@modules/auth/profile-loader';
import { Alert, Text, Button, Group } from '@mantine/core';
import { Link } from 'react-router-dom';

const DeclareStock: FC = () => {
  const profile = useProfile();

  return profile.mustDeclareStock ? (
    <Alert color="red" style={{ border: '1px solid red' }} mb="xl">
      <Group>
        <Text>Vous êtes invité à déclarer votre stock restant de produits Sentri Tech</Text>

        <Button component={Link} to="/stocks" color="red">
          Déclarer mon stock
        </Button>
      </Group>
    </Alert>
  ) : null;
};

export default DeclareStock;
