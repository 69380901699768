import { ReactNode } from 'react';
import { showNotification } from '@mantine/notifications';
import { HttpResult, HttpStatusCode, HttpTask } from '@core/http';
import * as EI from 'fp-ts/Either';
import * as T from 'fp-ts/Task';
import { pipe } from 'fp-ts/function';
import * as O from 'fp-ts/Option';

export type FlashNotificationType = 'error' | 'success';

export function showFlashNotification(type: FlashNotificationType, message: ReactNode) {
  return showNotification({
    message,
    styles: () => ({
      root: {
        backgroundColor: type === 'success' ? 'green' : 'red',
        borderColor: type === 'success' ? 'green' : 'red',

        '&::before': {
          display: 'none',
        },
      },
      description: {
        color: 'white',
      },
      closeButton: {
        color: 'white',
      },
    }),
  });
}

export interface HttpFlashNotificationOptions {
  hideError?: true | Array<HttpStatusCode>;
  successMessage?: ReactNode;
}

export function showFlashFromHttpResult<R, E>({ hideError, successMessage }: HttpFlashNotificationOptions = {}): (
  res: HttpResult<R, E>,
) => void {
  return res => {
    if (EI.isRight(res) && successMessage) {
      showFlashNotification('success', successMessage);
    } else if (EI.isLeft(res)) {
      const show = pipe(
        O.fromNullable(hideError),
        O.fold(
          () => true,
          hide => (Array.isArray(hide) ? !hide.includes(res.left.status) : false),
        ),
      );

      if (show) {
        const message = pipe(
          O.fromNullable(res.left.message),
          O.getOrElse(() => 'Une erreur technique est survenue'),
        );

        showFlashNotification('error', message);
      }
    }
  };
}

export function showFlashFromHttpTask<R, E>(
  options?: HttpFlashNotificationOptions,
): (task: HttpTask<R, E>) => HttpTask<R, E> {
  return task =>
    pipe(
      task,
      T.chainFirstIOK(res => () => showFlashFromHttpResult(options)(res)),
    );
}
