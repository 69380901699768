import { RefObject, useCallback, useContext, useEffect, useRef } from 'react';
import { useForm, UseFormProps, UseFormReturn } from 'react-hook-form';
import { Navigator, resolvePath, To, UNSAFE_NavigationContext, useLocation } from 'react-router-dom';
import * as IO from 'fp-ts/IO';
import {
  EnhancedFormContext,
  EnhancedFormContextValue,
  EnhancedFormExposedMethods,
} from '@shared/modules/form/components/EnhancedForm';
import { FieldValues } from 'react-hook-form/dist/types/fields';

interface ExternalSubmitValue<Values extends FieldValues> {
  formRef: RefObject<EnhancedFormExposedMethods>;
  form: UseFormReturn<Values>;
  resetFormChanged: () => void;
  handleFormSubmit: () => void;
  showCancelButton: boolean;
}

export function useEnhancedForm<T extends FieldValues>(formProps: UseFormProps<T>): ExternalSubmitValue<T> {
  const form = useForm<T>(formProps);

  const formRef = useRef<EnhancedFormExposedMethods>(null);

  const handleFormSubmit = useCallback(
    () => (formRef.current ? formRef.current.handleSubmit() : console.warn('No form ref to submit !')),
    [],
  );

  return {
    formRef,
    form,
    resetFormChanged: form.reset,
    showCancelButton: form.formState.isDirty,
    handleFormSubmit,
  };
}

export function useEnhancedFormContext<Values extends FieldValues>() {
  return useContext<EnhancedFormContextValue<Values>>(EnhancedFormContext);
}

type WaitingTransition =
  | { type: 'push'; args: Parameters<Navigator['push']> }
  | { type: 'replace'; args: Parameters<Navigator['replace']> }
  | { type: 'go'; args: Parameters<Navigator['go']> };

export const usePrompt = (openModal: IO.IO<void>, when: boolean): [IO.IO<void>, (to: To) => void, () => void] => {
  const { navigator } = useContext(UNSAFE_NavigationContext);

  const location = useLocation();

  const safePush = useRef(navigator.push);
  const safeReplace = useRef(navigator.replace);
  const safeGo = useRef(navigator.go);

  const waitingTransition = useRef<WaitingTransition | null>(null);

  useEffect(() => {
    if (!when) {
      return;
    }

    const push = navigator.push;
    const replace = navigator.replace;
    const go = navigator.go;

    navigator.push = (...args: Parameters<Navigator['push']>) => {
      if (location.pathname !== resolvePath(args[0]).pathname) {
        waitingTransition.current = { type: 'push', args };
        openModal();
      } else {
        push(...args);
      }
    };

    navigator.replace = (...args: Parameters<Navigator['replace']>) => {
      if (location.pathname !== resolvePath(args[0]).pathname) {
        waitingTransition.current = { type: 'replace', args };
        openModal();
      } else {
        replace(...args);
      }
    };

    navigator.go = (...args: Parameters<Navigator['go']>) => {
      waitingTransition.current = { type: 'go', args };
      openModal();
    };

    return () => {
      navigator.push = push;
      navigator.replace = replace;
      navigator.go = go;
    };
  }, [location.pathname, navigator, openModal, when]);

  const handleLeave = () => {
    const transition = waitingTransition.current;

    if (transition) {
      switch (transition.type) {
        case 'push':
          safePush.current(...transition.args);
          break;
        case 'replace':
          safeReplace.current(...transition.args);
          break;
        case 'go':
          safeGo.current(...transition.args);
          break;
      }
    }
  };

  const handleResetWaitingTransition = () => (waitingTransition.current = null);

  const forceNavigate = (to: To) => safePush.current(to);

  return [handleLeave, forceNavigate, handleResetWaitingTransition];
};
