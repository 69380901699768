import React, { ChangeEventHandler, FC, useState } from 'react';
import * as O from 'fp-ts/Option';
import * as A from 'fp-ts/Array';
import { pipe } from 'fp-ts/function';
import { Button, Group, Paper, Text } from '@mantine/core';
import { IconTools, IconUpload } from '@tabler/icons-react';
import { useEnhancedFormContext } from '@shared/modules/form';
import { CaseImportError, ImportCaseFormBody } from '@modules/import/model';
import DeclareStock from '@shared/modules/stock/DeclareStock';
import ImportFormInfos from '@modules/import/components/form/infos/ImportFormInfos';
import ImportFormErrors from '@modules/import/components/form/errors/ImportFormErrors';
import ImportFormSaved from '@modules/import/components/form/saved/ImportFormSaved';
import { showFlashNotification } from '@shared/modules/notification/utils';

const acceptFile = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';

interface ImportFormProps {
  loading: boolean;
  onSubmit: () => void;
  errors: Array<CaseImportError> | null;
  saved: number | null;
}

const ImportForm: FC<ImportFormProps> = ({ loading, onSubmit, errors, saved }) => {
  const [error, setError] = useState<boolean>(false);

  const { getValues, setValue, watch, handleSubmit } = useEnhancedFormContext<ImportCaseFormBody>();

  const handleSubmitForm = () => {
    setError(false);
    if (getValues('file') === undefined) {
      setError(true);
    } else {
      onSubmit();
      setValue('file', undefined);
    }
  };

  const importFile: ChangeEventHandler<HTMLInputElement> = e => {
    const file = pipe(
      O.fromNullable(e.target.files),
      O.chain(files => A.head(Array.from(files))),
      O.filter(file => file.type === acceptFile),
      O.toNullable,
    );

    if (file) setValue('file', file);
    else showFlashNotification('error', 'Veuillez importer un fichier au format .xlsx');
  };

  return (
    <form onSubmit={handleSubmit} noValidate>
      <DeclareStock />

      <Text>À partir d'un fichier Excel, importez vos chantiers.</Text>

      <Text>Vous pourrez ainsi informer Liphatech des chantiers en cours pour facturation.</Text>

      <Text weight={700} pt="md">
        Veuillez suivre la procédure
      </Text>

      {errors && errors.length > 0 ? (
        <ImportFormErrors errors={errors} />
      ) : saved && saved !== 0 ? (
        <ImportFormSaved />
      ) : (
        <ImportFormInfos />
      )}

      {saved === null || saved === 0 ? (
        <>
          <Paper mt="md" p="xl">
            <Text weight={600}>3. Dépôt du fichier Excel {errors && errors.length > 0 ? 'corrigé' : null}</Text>

            <Text>Déposez votre fichier.</Text>

            <Group pt="md">
              <input
                id="file"
                type="file"
                accept={acceptFile}
                style={{ display: 'none' }}
                value=""
                onChange={importFile}
              />

              <Button component="label" htmlFor="file">
                <IconUpload />
                <Text pl="xs">Choisir mon fichier</Text>
              </Button>

              {watch('file') != null ? (
                watch('file')?.name
              ) : error ? (
                <Text color="red">Veuillez choisir un fichier</Text>
              ) : null}
            </Group>
          </Paper>

          <Group position="right" pt="md">
            <Button loading={loading} onClick={handleSubmitForm}>
              <IconTools />
              <Text pl="xs">Vérifier mon fichier</Text>
            </Button>
          </Group>
        </>
      ) : null}
    </form>
  );
};

export default ImportForm;
