import React, { FC } from 'react';
import router from 'routes';
import { HelmetProvider } from 'react-helmet-async';
import { GlobalStyles } from '@styles/global';
import MantineTheme from '@styles/mantine';
import SentryProvider from '@shared/modules/sentry/components/SentryProvider';
import { NavigationProgress } from '@mantine/nprogress';
import { RouterProvider } from 'react-router-dom';
import ApiDownIndicator from '@core/http/components/ApiDownIndicator';

const App: FC = () => {
  return (
    <SentryProvider>
      <MantineTheme>
        <GlobalStyles />

        <ApiDownIndicator />

        <NavigationProgress color="blue" size={2} />

        <HelmetProvider>
          <RouterProvider router={router} />
        </HelmetProvider>
      </MantineTheme>
    </SentryProvider>
  );
};

export default App;
