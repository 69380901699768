import React from 'react';
import { Route } from 'react-router-dom';
import { createRoute } from '@core/router';

import casesListRoute from '@modules/cases/ListPage';
import createCaseRoute from '@modules/cases/CreatePage';
import caseDetailRoute from '@modules/cases/DetailPage';

const casesRoutes = (
  <Route path="cases">
    <Route index {...createRoute(casesListRoute)} />
    <Route path="new" {...createRoute(createCaseRoute)} />
    <Route path=":id" {...createRoute(caseDetailRoute)} />
  </Route>
);

export default casesRoutes;
