import React, { FC } from 'react';
import * as Styled from './Form.styles';
import { Button, NumberInput, Paper, Select, Table, Text, TextInput } from '@mantine/core';
import { useEnhancedFormContext } from '@shared/modules/form';
import { caseTypeLabel, CreateCaseFormBody } from '@modules/cases/model';
import { getDropdownOptionsFromEnumLabels } from '@shared/utils/enum';
import { Controller, useFieldArray } from 'react-hook-form';
import { IconArrowLeft, IconDeviceFloppy } from '@tabler/icons-react';
import { Link } from 'react-router-dom';
import { DatePickerInput } from '@mantine/dates';
import 'dayjs/locale/fr';

interface CreateFormProps {
  loading: boolean;
  onSubmit: () => void;
}

const CreateForm: FC<CreateFormProps> = ({ loading, onSubmit }) => {
  const {
    control,
    register,
    formState: { errors },
    handleSubmit,
  } = useEnhancedFormContext<CreateCaseFormBody>();

  const { fields } = useFieldArray({ control, name: 'products' });

  return (
    <form onSubmit={handleSubmit} noValidate>
      <Text fw={700}>Informations générales</Text>

      <Paper p="xl" mt="xs">
        <TextInput
          id="name"
          label="Référence du chantier"
          placeholder="Référence du chantier"
          required
          error={!!errors.name}
          {...register('name')}
        />

        <TextInput
          id="customerName"
          label="Client"
          placeholder="Client"
          required
          error={!!errors.customerName}
          {...register('customerName')}
          pt="md"
        />

        <Styled.FormAdressContainer pt="md">
          <Styled.FormAddressFirstLine
            id="address.firstLine"
            label="Adresse du chantier"
            placeholder="Adresse du chantier"
            required
            error={!!errors.address?.firstLine}
            {...register('address.firstLine')}
          />

          <Styled.FormAddressPostalCode
            id="address.postalCode"
            label="CP"
            placeholder="00000"
            required
            error={!!errors.address?.postalCode}
            {...register('address.postalCode')}
          />

          <Styled.FormAddressCity
            id="address.city"
            label="Ville"
            placeholder="Ville"
            required
            error={!!errors.address?.city}
            {...register('address.city')}
          />
        </Styled.FormAdressContainer>
      </Paper>

      <Text fw={700} pt="xl">
        Installation
      </Text>

      <Paper p="xl" mt="xs">
        <Text>
          Pour pouvoir déclarer un nouveau chantier, ce dernier doit être installé (constat d'installation envoyé au
          client)
        </Text>

        <Controller
          control={control}
          name="installDate"
          render={({ field }) => (
            <DatePickerInput
              valueFormat="DD/MM/YYYY"
              placeholder="JJ/MM/AAAA"
              label="Date d'installation"
              error={!!errors.installDate}
              value={field.value}
              onChange={field.onChange}
              maxDate={new Date()}
              locale="fr"
              required
              mt="md"
            />
          )}
        />

        <Controller
          control={control}
          name="type"
          render={({ field }) => (
            <Select
              label="Quel est le type de votre chantier"
              placeholder="Choisir le type"
              data={getDropdownOptionsFromEnumLabels(caseTypeLabel)}
              value={field.value}
              error={!!errors.type}
              onChange={field.onChange}
              required
              mt="md"
            />
          )}
        />
      </Paper>

      <Text fw={700} pt="xl">
        Superficies
      </Text>

      <Paper p="xl" mt="xs">
        <Styled.FormSurface>
          <Styled.FormSurfaceText>
            <Text>
              Superficie du bâti en m² <span>*</span>
            </Text>
          </Styled.FormSurfaceText>

          <Styled.FormSurfaceInput>
            <Controller
              control={control}
              name="zoneInfo.buildingSurface"
              render={({ field }) => (
                <NumberInput
                  type="number"
                  placeholder="Superficie du bâti"
                  error={!!errors.zoneInfo?.buildingSurface}
                  value={field.value ?? undefined}
                  onChange={field.onChange}
                  style={{ width: '100%' }}
                  min={0}
                  hideControls
                />
              )}
            />
          </Styled.FormSurfaceInput>
        </Styled.FormSurface>

        <Styled.FormSurface pt="md">
          <Styled.FormSurfaceText>
            <Text>
              Superficie du terrain en m² <span>*</span>
            </Text>
          </Styled.FormSurfaceText>

          <Styled.FormSurfaceInput>
            <Controller
              control={control}
              name="zoneInfo.landSurface"
              render={({ field }) => (
                <NumberInput
                  type="number"
                  placeholder="Superficie du terrain"
                  error={!!errors.zoneInfo?.landSurface}
                  value={field.value ?? undefined}
                  onChange={field.onChange}
                  style={{ width: '100%' }}
                  min={0}
                  hideControls
                />
              )}
            />
          </Styled.FormSurfaceInput>
        </Styled.FormSurface>
      </Paper>

      <Text fw={700} pt="xl">
        Produits utilisés
      </Text>

      <Paper p="xl" mt="xs">
        <Text>Merci de renseigner le nombre de produits installés sur votre chantier</Text>

        <Table striped withBorder mt="xl">
          <thead>
            <tr>
              <th>Produit</th>
              <th>Quantité</th>
            </tr>
          </thead>
          <tbody>
            {fields.map((product, index) => (
              <tr key={index}>
                <td>{product.label}</td>
                <td>
                  <Controller
                    control={control}
                    name={`products.${index}.qte`}
                    render={({ field }) => (
                      <NumberInput
                        type="number"
                        placeholder="Quantité"
                        error={!!errors.products?.[index]?.qte}
                        value={field.value ?? undefined}
                        onChange={field.onChange}
                        min={0}
                        hideControls
                      />
                    )}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Paper>

      <Styled.FormActions>
        <Button color="gray" leftIcon={<IconArrowLeft />} component={Link} to="/">
          Annuler
        </Button>

        <Button leftIcon={<IconDeviceFloppy />} loading={loading} onClick={onSubmit}>
          Enregistrer
        </Button>
      </Styled.FormActions>
    </form>
  );
};

export default CreateForm;
