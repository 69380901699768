import { HttpRange, httpService, HttpTask } from '@core/http';
import {
  Case,
  CaseId,
  CaseProducts,
  CasesRangeFilter,
  CreateCaseParams,
  DetailCase,
  UpdateCaseParams,
} from '@modules/cases/model';

const URI = '/cases';

export function getCasesRange(page: number, filter: CasesRangeFilter): HttpRange<Case, CasesRangeFilter> {
  return httpService.getRange(URI, page, filter);
}

export function createCase(params: CreateCaseParams): HttpTask<DetailCase> {
  return httpService.post(URI, params);
}

export function getCaseDetail(id: CaseId): HttpTask<DetailCase> {
  return httpService.get(`${URI}/${id}`);
}

export function updateCase(id: CaseId, params: UpdateCaseParams): HttpTask<DetailCase> {
  return httpService.post(`${URI}/${id}`, params);
}

export function deleteCase(id: CaseId): HttpTask {
  return httpService.delete(`${URI}/${id}`);
}

export function getProducts(): HttpTask<Array<CaseProducts>> {
  return httpService.get(`${URI}/products`);
}
