import * as O from 'fp-ts/Option';
import * as TE from 'fp-ts/TaskEither';
import { pipe } from 'fp-ts/function';
import { HttpTask } from '@core/http';
import { EnhancedFormSubmitResult } from '@shared/modules/form/components/EnhancedForm';

export function mapToEnhancedFormSubmitResult<T>(
  fn?: (right: T) => string,
): (task: HttpTask<T>) => EnhancedFormSubmitResult {
  return task =>
    pipe(
      task,
      TE.map(right =>
        pipe(
          O.fromNullable(fn),
          O.map(fn => fn(right)),
        ),
      ),
    );
}
