import Seo from '@shared/modules/seo/Seo';
import { renderOptional } from '@shared/utils/render';
import { pipe } from 'fp-ts/function';
import * as O from 'fp-ts/Option';
import React, { FC, PropsWithChildren, ReactNode } from 'react';
import { Link, NavLink, resolvePath, To, useLocation } from 'react-router-dom';
import * as Styled from './Page.styles';
import { Anchor, Box, Breadcrumbs, Group, Paper, Tabs, Text, Title } from '@mantine/core';
import { IconChevronRight, IconPlus } from '@tabler/icons-react';
import { filterEmptyStringToOption } from '@shared/utils/string';

export const PAGE_SCROLLER_ID = 'page-scroller';

export interface Tab {
  title: string;
  to: To;
  exact?: boolean;
}

export interface Breadcrumb {
  title: ReactNode;
  to?: To;
}

export interface BottomBarProps {
  left?: ReactNode;
  right?: ReactNode;
}

export interface PageLink {
  label: string;
  link: string;
}

export interface PageProps {
  title?: ReactNode;
  seoTitle?: string;
  breadcrumbs?: Breadcrumb | Array<Breadcrumb>;
  tabs?: Array<Tab>;
  bottom?: BottomBarProps;
  link?: PageLink;
}

const PageTabs: FC<{ tabs?: Array<Tab> }> = ({ tabs }) => {
  const location = useLocation();

  if (tabs && tabs.length) {
    return (
      <Paper>
        <Tabs value={location.pathname}>
          <Tabs.List>
            {tabs.map((tab, i) => (
              <Tabs.Tab value={resolvePath(tab.to).pathname} key={i} sx={{ padding: 0 }}>
                <Anchor component={NavLink} to={tab.to} sx={{ display: 'block', padding: '10px 20px' }}>
                  {tab.title}
                </Anchor>
              </Tabs.Tab>
            ))}
          </Tabs.List>
        </Tabs>
      </Paper>
    );
  }

  return null;
};

const PageBottomBar: FC<{ bottom?: BottomBarProps }> = ({ bottom }) => {
  const optBottom = O.fromNullable(bottom);

  const leftContent = pipe(
    optBottom,
    O.chainNullableK(b => b.left),
  );

  const rightContent = pipe(
    optBottom,
    O.chainNullableK(b => b.right),
  );

  if (O.isSome(leftContent) || O.isSome(rightContent)) {
    return (
      <Styled.PageBottom>
        <div>
          <div className="left">{O.toNullable(leftContent)}</div>
          <div className="right">{O.toNullable(rightContent)}</div>
        </div>
      </Styled.PageBottom>
    );
  }

  return null;
};

const Page: FC<PropsWithChildren<PageProps>> = ({ title, seoTitle, breadcrumbs, tabs, bottom, link, children }) => {
  const breadcrumbsOpts = pipe(
    O.fromNullable(breadcrumbs),
    O.map(breadcrumbs => (Array.isArray(breadcrumbs) ? breadcrumbs : [breadcrumbs])),
  );

  const titleOps = O.fromNullable(title);

  const computeSeoTitle = pipe(
    filterEmptyStringToOption(seoTitle),
    O.alt(() => (typeof title === 'string' ? O.some(title) : O.none)),
    O.toUndefined,
  );

  return (
    <Styled.PageContainer>
      <Seo title={computeSeoTitle} />

      {O.isSome(titleOps) || O.isSome(breadcrumbsOpts) ? (
        <Styled.PageTopContainer>
          <Styled.PageTop>
            <Styled.PageTopLeft>
              {renderOptional(titleOps, title => (
                <Box my={10}>{typeof title === 'string' ? <Title>{title}</Title> : title}</Box>
              ))}

              {renderOptional(breadcrumbsOpts, breadcrumbs => (
                <Breadcrumbs my={10} separator={<IconChevronRight size={15} />} sx={{ alignItems: 'center' }}>
                  {breadcrumbs.map((item, i) =>
                    item.to ? (
                      <Anchor size="sm" key={i} component={Link} color="blue" to={item.to} weight="500">
                        {item.title}
                      </Anchor>
                    ) : (
                      <Text key={i} size="sm">
                        {item.title}
                      </Text>
                    ),
                  )}
                </Breadcrumbs>
              ))}
            </Styled.PageTopLeft>

            {link != null ? (
              <Styled.PageTopRight>
                <Group position="right">
                  <Styled.PageTopRightButton component={Link} to={link.link} leftIcon={<IconPlus />} mt="md">
                    <p>{link.label}</p>
                  </Styled.PageTopRightButton>
                </Group>
              </Styled.PageTopRight>
            ) : null}
          </Styled.PageTop>
        </Styled.PageTopContainer>
      ) : null}

      <PageTabs tabs={tabs} />

      <Styled.PageContentWrapper id={PAGE_SCROLLER_ID}>
        <Styled.PageContent>
          <Styled.PageChildren>{children}</Styled.PageChildren>
        </Styled.PageContent>
      </Styled.PageContentWrapper>

      <PageBottomBar bottom={bottom} />
    </Styled.PageContainer>
  );
};

export default Page;
