import styled from '@emotion/styled';
import { Group, TextInput } from '@mantine/core';

export const FormAdressContainer = styled(Group)`
  display: grid;
  grid-template-areas: 'firstLine postalCode city';
  grid-template-columns: 7fr 2fr 3fr;
  grid-column-gap: 15px;

  @media screen and (max-width: 760px) {
    grid-template-columns: 1fr 1fr;
    grid-template-areas:
      'firstLine firstLine'
      'postalCode city';
  }
`;

export const FormAddressFirstLine = styled(TextInput)`
  grid-area: firstLine;
`;

export const FormAddressPostalCode = styled(TextInput)`
  grid-area: postalCode;
`;

export const FormAddressCity = styled(TextInput)`
  grid-area: city;
`;

export const FormSurface = styled(Group)`
  display: grid;
  grid-template-columns: 1fr 3fr;
  grid-template-areas: 'surfaceText surfaceInput';
  grid-column-gap: 15px;
  align-items: center;

  @media screen and (max-width: 760px) {
    grid-template-columns: 1fr;
    grid-template-areas:
      'surfaceText'
      'surfaceInput';
  }
`;

export const FormSurfaceText = styled(Group)`
  grid-area: surfaceText;

  span {
    color: red;
  }
`;

export const FormSurfaceInput = styled(Group)`
  grid-area: surfaceInput;
`;

export const FormActions = styled.div`
  padding-top: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;

  > button {
    margin-left: 16px;
  }

  @media screen and (max-width: 760px) {
    flex-direction: column-reverse;
    justify-content: center;

    > button {
      margin-left: 0;
      margin-bottom: 10px;
    }
  }
`;
