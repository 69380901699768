import { constFalse, pipe } from 'fp-ts/function';
import * as O from 'fp-ts/Option';
import * as EI from 'fp-ts/Either';
import React, { FC } from 'react';
import { defineRoute } from '@core/router';
import AuthLayout from '@modules/auth/components/layout/AuthLayout';
import PasswordsForm from '@modules/auth/components/passwords/PasswordsForm';
import { PasswordsFormBody, ResetPasswordToken } from '@modules/auth/model';
import * as AuthService from '@modules/auth/service';
import Seo from '@shared/modules/seo/Seo';
import { defineLoader, httpTaskToResponseTask, useLoader } from '@core/router/loader';
import { z } from 'zod';
import { defineAction, useAction } from '@core/router/action';
import { createNewTypeStringSchema } from '@shared/schemas';

const tokenSchema = z.object({ token: createNewTypeStringSchema<ResetPasswordToken>() });

const loader = defineLoader({
  params: tokenSchema,
  handler: ({ params }) => httpTaskToResponseTask(AuthService.getPasswordResetDetail(params.token)),
});

const actions = {
  reset: defineAction({
    type: 'reset',
    params: tokenSchema,
    payload: z.object({ password: z.string() }),
    handler: ({ params, payload }) => AuthService.resetPassword(params.token, payload),
    flashOptions: {
      error: constFalse,
      success: () => 'Mot de passe modifié avec succès.',
    },
    redirect: ({ result }) => (EI.isRight(result) ? '/login' : null),
  }),
};

const ResetPasswordPage: FC = () => {
  const { email } = useLoader<typeof loader>();

  const [loading, send, error] = useAction(actions.reset);

  const handleResetPassword = ({ password }: PasswordsFormBody) => send({ password });

  const errorMessage = pipe(
    error,
    O.map(() => 'Une erreur technique est survenue.'),
  );

  return (
    <AuthLayout title="Changement de mot de passe">
      <Seo title="Mot de passe oublié" />
      <PasswordsForm email={email} loading={loading} error={errorMessage} onSubmit={handleResetPassword} />
    </AuthLayout>
  );
};

const resetPasswordRoute = defineRoute({
  component: ResetPasswordPage,
  loader,
  actions,
});

export default resetPasswordRoute;
