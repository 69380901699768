import React, { FC, useState } from 'react';
import * as Styled from './UpdateProfileForm.styles';
import { EnhancedForm, useEnhancedForm, useEnhancedFormContext } from '@shared/modules/form';
import { UpdateEmailParams, updateEmailSchema, UpdateProfileParams } from '@modules/auth/model';
import { Paper, TextInput, Text, Button, Anchor } from '@mantine/core';
import { IconDeviceFloppy, IconEdit } from '@tabler/icons-react';
import UpdateProfileFormModal from '@modules/auth/components/profile/modal/UpdateProfileFormModal';
import { zodResolver } from '@hookform/resolvers/zod';
import { EnhancedFormSubmitResult } from '@shared/modules/form/components/EnhancedForm';

interface UpdateProfileFormProps {
  email: string;
  loading: boolean;
  onSubmit: () => void;
  loadingEmail: boolean;
  onSubmitEmail: (params: UpdateEmailParams) => EnhancedFormSubmitResult;
  onSubmitPassword: () => void;
}

const UpdateProfileForm: FC<UpdateProfileFormProps> = ({
  email,
  loading,
  onSubmit,
  loadingEmail,
  onSubmitEmail,
  onSubmitPassword,
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const { formRef, form, handleFormSubmit } = useEnhancedForm({
    resolver: zodResolver(updateEmailSchema),
    defaultValues: {
      email: '',
    },
  });

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useEnhancedFormContext<UpdateProfileParams>();

  return (
    <form onSubmit={handleSubmit} noValidate>
      <Text fw={700}>Mes informations personnelles</Text>

      <Paper p="xl" mt="xs">
        <Styled.UpdateProfileFormEmailLabel>
          <Text size={14} weight={600} color="#212529" pb={5}>
            E-mail
          </Text>
          <Text color="#fa5252" pl={5}>
            *
          </Text>
        </Styled.UpdateProfileFormEmailLabel>
        <Styled.UpdateProfileFormEmail onClick={() => setIsOpen(true)}>
          <Text size={14} color="#909296">
            {email}
          </Text>

          <IconEdit width={24} />
        </Styled.UpdateProfileFormEmail>

        <Styled.UpdateProfileFormInfos pt="md">
          <TextInput id="lastName" label="Nom" placeholder="Nom" error={!!errors.lastName} {...register('lastName')} />

          <TextInput
            id="firstName"
            label="Prénom"
            placeholder="Prénom"
            error={!!errors.firstName}
            {...register('firstName')}
          />
        </Styled.UpdateProfileFormInfos>
      </Paper>

      <Text mt="xl" fw={700}>
        Ma société
      </Text>

      <Paper p="xl" mt="xs">
        <TextInput
          id="entityName"
          label="Nom de la société"
          placeholder="Nom de la société"
          error={!!errors.entityName}
          required
          {...register('entityName')}
        />
      </Paper>

      <Styled.UpdateProfileFormActions>
        <Anchor component="button" underline onClick={onSubmitPassword}>
          Modifier mon mot de passe
        </Anchor>

        <Button leftIcon={<IconDeviceFloppy />} loading={loading} onClick={onSubmit}>
          Enregistrer
        </Button>
      </Styled.UpdateProfileFormActions>

      <EnhancedForm ref={formRef} form={form} onSubmit={onSubmitEmail} preventLeave>
        <UpdateProfileFormModal
          isOpen={isOpen}
          onClose={() => setIsOpen(false)}
          loadingEmail={loadingEmail}
          onSubmitEmail={handleFormSubmit}
        />
      </EnhancedForm>
    </form>
  );
};

export default UpdateProfileForm;
