import React from 'react';
import { Route } from 'react-router-dom';
import { createRoute } from '@core/router';

import importRoute from '@modules/import/ImportPage';

const importRoutes = (
  <Route path="import">
    <Route index {...createRoute(importRoute)} />
  </Route>
);

export default importRoutes;
