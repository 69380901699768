import z from 'zod';

export enum CaseImportErrorCode {
  Required = 'error.import.required_field_not_found',
  WrongFormat = 'error.import.wrong_format',
  WrongType = 'error.import.wrong_type_value',
  InvalidDate = 'error.import.invalid_date',
  Unexpected = 'error.import.unexpected',
  ConversionError = 'error.import.conversion_error',
  InvalidCaseName = 'error.import.invalid_case_name',
}

export const caseImportErrorCodeLabel: Record<CaseImportErrorCode, string> = {
  [CaseImportErrorCode.Required]: 'Champ obligatoire',
  [CaseImportErrorCode.WrongFormat]: 'Format incorrect',
  [CaseImportErrorCode.WrongType]: 'Type inconnu',
  [CaseImportErrorCode.InvalidDate]: 'Date invalide',
  [CaseImportErrorCode.Unexpected]: 'Erreur inattendue',
  [CaseImportErrorCode.ConversionError]: 'Erreur de conversion de la donnée',
  [CaseImportErrorCode.InvalidCaseName]: "Ce nom de chantier existe déjà dans le fichier d'import",
};

export interface CaseImportError {
  code: CaseImportErrorCode;
  sheet: string;
  line: number;
  column: number;
  columnString: string;
  value: string;
  message: string;
}

export interface CaseImport {
  errors: Array<CaseImportError>;
  saved: number;
}

export const importCaseFormBodySchema = z.object({
  file: z.instanceof(File).nullish(),
});

export type ImportCaseFormBody = z.infer<typeof importCaseFormBodySchema>;
