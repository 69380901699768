import Nav from '@layout/nav/Nav';
import React, { FC, Suspense } from 'react';
import { Outlet } from 'react-router-dom';

import * as Styled from './Layout.styles';
import { DebouncedLineLoader } from './loaders/line-loader/LineLoader';
import { useProfile } from '@modules/auth/profile-loader';

const Layout: FC = () => {
  const profile = useProfile();

  return (
    <Styled.LayoutContainer>
      <Styled.LayoutNav>
        <Nav profile={profile} />
      </Styled.LayoutNav>

      <Styled.LayoutContent>
        <Suspense fallback={<DebouncedLineLoader />}>
          <Outlet />
        </Suspense>
      </Styled.LayoutContent>
    </Styled.LayoutContainer>
  );
};

export default Layout;
