import React, { FC } from 'react';

import * as O from 'fp-ts/Option';

import { PasswordsFormBody, passwordsSchema } from '@modules/auth/model';
import { renderOptional } from '@shared/utils/render';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { Alert, Button, Group, PasswordInput, Text, TextInput } from '@mantine/core';
import styled from '@emotion/styled';
import Copyright from '@shared/modules/copyright/Copyright';

const PasswordsFormContainer = styled.form`
  width: 400px;

  @media screen and (max-width: 440px) {
    width: 100%;
  }
`;

const PasswordsFormTextInput = styled(TextInput)`
  > label {
    color: #ffffff;
  }
`;

const PasswordsFormInput = styled(PasswordInput)`
  > label {
    color: #ffffff;
  }
`;

interface PasswordsFormProps {
  email: string;
  loading: boolean;
  error: O.Option<string>;
  onSubmit: (params: PasswordsFormBody) => void;
}

const PasswordsForm: FC<PasswordsFormProps> = ({ email, loading, error, onSubmit }) => {
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm<PasswordsFormBody>({
    resolver: zodResolver(passwordsSchema),
    defaultValues: {
      password: '',
      confirmPassword: '',
    },
  });

  return (
    <PasswordsFormContainer onSubmit={handleSubmit(onSubmit)} noValidate>
      {renderOptional(error, error => (
        <Alert color="red" mb={10}>
          <Text color="red" size="sm" weight={600} align="center">
            {error}
          </Text>
        </Alert>
      ))}

      <PasswordsFormTextInput mt={10} value={email} label="Email" radius="md" mb="md" disabled />

      <PasswordsFormInput
        id="password"
        mt="sm"
        error={!!errors.password}
        placeholder="Entrez votre mot de passe"
        label="Mot de passe"
        radius="md"
        mb="md"
        {...register('password')}
      />

      <PasswordsFormInput
        id="confirmPassword"
        mt="sm"
        error={errors.confirmPassword?.message}
        placeholder="Confirmer le mot de passe"
        label="Confirmer le mot de passe"
        radius="md"
        {...register('confirmPassword')}
      />

      <Group position="center" mt={30}>
        <Button type="submit" loading={loading} disabled={loading}>
          Valider
        </Button>
      </Group>

      <Copyright />
    </PasswordsFormContainer>
  );
};

export default PasswordsForm;
