import logo from '@assets/logos/logo.svg';
import { Profile } from '@modules/auth/model';
import React, { FC, useState } from 'react';
import * as Styled from './Nav.styles';
import { Avatar, Popover, Text } from '@mantine/core';
import { getUserInitial, getUserName } from '@shared/utils/users';

interface NavProps {
  profile: Profile;
}

const Nav: FC<NavProps> = ({ profile }) => {
  const [opened, setOpened] = useState<boolean>(false);

  return (
    <Styled.NavContainer>
      <Styled.NavHeaderLogo to="/">
        <img src={logo} alt="logo" width={200} height={60} />
      </Styled.NavHeaderLogo>

      <Styled.NavUserContainer>
        <Popover opened={opened} onChange={setOpened} position="bottom-end" shadow="md">
          <Popover.Target>
            <Styled.NavUser onClick={() => setOpened(o => !o)}>
              <Styled.NavUserLabel>
                {getUserName(profile.email, profile.firstName, profile.lastName)}
              </Styled.NavUserLabel>

              <Avatar radius="xl" color="primary" variant="filled">
                <Text fw={600}>{getUserInitial(profile.email, profile.firstName, profile.lastName)}</Text>
              </Avatar>
            </Styled.NavUser>
          </Popover.Target>

          <Popover.Dropdown mt={-10}>
            <Styled.NavUserDropdown>
              <Styled.NavUserDropdownLink to="/profile" color="dark" onClick={() => setOpened(false)}>
                Mon compte
              </Styled.NavUserDropdownLink>

              <Styled.NavUserDropdownLink
                to="https://facturationsimplifieesentriprime.renderforestsites.com/"
                target="_blank"
                rel="noopeneer noreferrer"
                onClick={() => setOpened(false)}
              >
                CGU
              </Styled.NavUserDropdownLink>

              <Styled.NavUserDropdownLink to="/logout">Déconnexion</Styled.NavUserDropdownLink>
            </Styled.NavUserDropdown>
          </Popover.Dropdown>
        </Popover>
      </Styled.NavUserContainer>
    </Styled.NavContainer>
  );
};

export default Nav;
