import * as AuthService from '@modules/auth/service';
import { defineRoute } from '@core/router';
import { defineLoader } from '@core/router/loader';

const loader = defineLoader({
  handler: () => AuthService.logout(),
  redirect: () => '/login',
});

const logoutRoute = defineRoute({
  loader,
});

export default logoutRoute;
