import { StockDeclareParams } from '@modules/stocks/model';
import { httpService, HttpTask } from '@core/http';

const URI = '/stocks';

export function declareStocks(params: StockDeclareParams): HttpTask {
  return httpService.post(URI, params);
}

export function getUndeclaredStock(): HttpTask<StockDeclareParams> {
  return httpService.get(`${URI}/undeclared`);
}

export function getLastDeclared(): HttpTask<StockDeclareParams> {
  return httpService.get(`${URI}/last`);
}
