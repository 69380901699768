import React, { FC } from 'react';
import styled from '@emotion/styled';
import { Group, Text } from '@mantine/core';
import { IconCopyright } from '@tabler/icons-react';
import innovantic from '@assets/images/innovantic.svg';

const CopyrightContainer = styled(Group)`
  position: absolute;
  bottom: 20px;
  right: 20px;
  gap: 5px;
`;

const CopyrightLogo = styled.a`
  padding-left: 10px;
`;

const Copyright: FC = () => {
  return (
    <CopyrightContainer>
      <IconCopyright color="white" size={14} />

      <Text color="white" size={13}>
        Liphatech 2023 - Une solution
        <CopyrightLogo href="https://www.innovantic.fr" target="_blank" rel="noreferrer noopener">
          <img src={innovantic} alt="Innovantic logo" />
        </CopyrightLogo>
      </Text>
    </CopyrightContainer>
  );
};

export default Copyright;
