import { constFalse, pipe } from 'fp-ts/function';
import * as O from 'fp-ts/Option';
import React, { FC } from 'react';

import { HttpStatusCode } from '@core/http';
import { defineRoute } from '@core/router';
import AuthLayout from '@modules/auth/components/layout/AuthLayout';
import PasswordsForm from '@modules/auth/components/passwords/PasswordsForm';
import { ActivationToken, PasswordsFormBody } from '@modules/auth/model';
import * as AuthService from '@modules/auth/service';
import Seo from '@shared/modules/seo/Seo';
import { defineLoader, httpTaskToResponseTask, useLoader } from '@core/router/loader';
import { z } from 'zod';
import { defineAction, useAction } from '@core/router/action';
import * as EI from 'fp-ts/Either';
import { createNewTypeStringSchema } from '@shared/schemas';

const tokenSchema = z.object({ token: createNewTypeStringSchema<ActivationToken>() });

const loader = defineLoader({
  params: tokenSchema,
  handler: ({ params }) => httpTaskToResponseTask(AuthService.getActivationDetail(params.token)),
});

const actions = {
  activate: defineAction({
    type: 'activate',
    params: tokenSchema,
    payload: z.object({ password: z.string() }),
    handler: ({ params, payload }) => AuthService.activateAccount(params.token, payload),
    flashOptions: { error: constFalse },
    redirect: ({ result }) => (EI.isRight(result) ? '/' : null),
  }),
};

const ActivationPage: FC = () => {
  const { email } = useLoader<typeof loader>();

  const [loading, activate, error] = useAction(actions.activate);

  const handleActivateAccount = ({ password }: PasswordsFormBody) => activate({ password });

  const errorMessage = pipe(
    error,
    O.map(error =>
      HttpStatusCode.CONFLICT === error.status ? 'Compte déjà activé.' : 'Une erreur technique est survenue.',
    ),
  );

  return (
    <AuthLayout title="Activation">
      <Seo title="Activation" />
      <PasswordsForm email={email} loading={loading} error={errorMessage} onSubmit={handleActivateAccount} />
    </AuthLayout>
  );
};

const activationRoute = defineRoute({
  component: ActivationPage,
  loader,
  actions,
});

export default activationRoute;
