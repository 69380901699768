import React, { FC } from 'react';
import * as CaseService from './service';
import Page, { PageProps } from '@layout/page/Page';
import { defineRoute } from '@core/router';
import { defineLoader, httpTaskToResponseTask } from '@core/router/loader';
import { parseQueriesFormUrl } from '@shared/utils/queries';
import { getPageQuery } from '@shared/modules/range/utils';
import { Case, casesFilterParser, CasesRangeFilter } from '@modules/cases/model';
import { useRange, VirtualizedList } from '@shared/modules/range';
import { identity } from 'fp-ts/function';
import { Group } from '@mantine/core';
import { Filters } from '@shared/modules/filter/components';
import CasesFilter from '@modules/cases/components/list/CasesFilter';
import CaseRow from '@modules/cases/components/row/CaseRow';
import DeclareStock from '@shared/modules/stock/DeclareStock';

const loader = defineLoader({
  handler: ({ request }) => {
    const queries = parseQueriesFormUrl(request.url);

    return httpTaskToResponseTask(CaseService.getCasesRange(getPageQuery(queries), casesFilterParser(queries)));
  },
});

const CasesListPage: FC = () => {
  const { range, handleFilter, handleLoadPage } = useRange<typeof loader, Case, CasesRangeFilter>(identity);

  const pageProps: PageProps = {
    title: 'Mes chantiers',
    link: {
      link: '/cases/new',
      label: 'Nouveau',
    },
  };

  const handleClean = () => {
    handleFilter({
      search: null,
      status: null,
    });
  };

  return (
    <Page {...pageProps}>
      <DeclareStock />

      <Filters search filter={range.filter} onFilter={handleFilter} clean onClean={handleClean}>
        {props => <CasesFilter {...props} />}
      </Filters>

      <Group mt="md">
        <VirtualizedList range={range} loadPage={handleLoadPage}>
          {({ item, style, ref, index }) => <CaseRow item={item} index={index} forwardRef={ref} style={style} />}
        </VirtualizedList>
      </Group>
    </Page>
  );
};

const casesListRoute = defineRoute({
  component: CasesListPage,
  loader,
});

export default casesListRoute;
