import React, { FC, useState } from 'react';
import * as ImportService from './service';
import * as TE from 'fp-ts/TaskEither';
import { pipe } from 'fp-ts/function';
import { defineAction } from '@core/router/action';
import { defineRoute } from '@core/router';
import Page, { PageProps } from '@layout/page/Page';
import { CaseImportError, ImportCaseFormBody, importCaseFormBodySchema } from '@modules/import/model';
import ImportForm from '@modules/import/components/form/ImportForm';
import { EnhancedForm, mapToEnhancedFormSubmitResult, useEnhancedForm } from '@shared/modules/form';
import { zodResolver } from '@hookform/resolvers/zod';
import { createFormData } from '@shared/utils/formData';
import { useSendTask } from '@core/http/hooks';

const actions = {
  import: defineAction({
    type: 'import',
    formData: true,
    handler: ({ formData }) => ImportService.importCases(formData),
    flashOptions: {
      success: () => 'Chantiers importés avec succès',
    },
  }),
};

const ImportRoute: FC = () => {
  const [errors, setErrors] = useState<Array<CaseImportError>>([]);
  const [saved, setSaved] = useState<number | null>(null);
  const { formRef, form, handleFormSubmit } = useEnhancedForm<ImportCaseFormBody>({
    resolver: zodResolver(importCaseFormBodySchema),
  });

  const [loading, importCase] = useSendTask(ImportService.importCases);

  const pageProps: PageProps = {
    title: 'Import de chantier',
  };

  const handleImport = (params: ImportCaseFormBody) => {
    const formData = createFormData({ body: params.file });

    return pipe(
      () => importCase(formData),
      TE.chainFirstIOK(res => () => {
        setErrors(res.errors);
        setSaved(res.saved);
      }),
      mapToEnhancedFormSubmitResult(),
    );
  };

  return (
    <Page {...pageProps}>
      <EnhancedForm ref={formRef} form={form} onSubmit={handleImport}>
        <ImportForm loading={loading} onSubmit={handleFormSubmit} errors={errors} saved={saved} />
      </EnhancedForm>
    </Page>
  );
};

const importRoute = defineRoute({
  component: ImportRoute,
  actions,
});

export default importRoute;
