import queryString, { ParsedQuery, ParseOptions, StringifiableRecord } from 'query-string';
import { pipe } from 'fp-ts/function';
import * as R from 'fp-ts/Record';
import * as S from 'fp-ts/string';
import { camelToSnake, snakeToCamel } from '@shared/utils/string';
import { StringifyOptions } from 'query-string/base';

export function queriesToSnakeCase(queries: StringifiableRecord): StringifiableRecord {
  return pipe(
    queries,
    R.reduceWithIndex(S.Ord)({} as StringifiableRecord, (key, acc, value) => ({
      ...acc,
      [camelToSnake(key)]: value,
    })),
  );
}

export function queriesToCamelCase(queries: ParsedQuery): ParsedQuery {
  return pipe(
    queries,
    R.reduceWithIndex(S.Ord)({} as ParsedQuery, (key, acc, value) => ({
      ...acc,
      [snakeToCamel(key)]: value,
    })),
  );
}

const stringifyOptions: StringifyOptions = {
  skipEmptyString: true,
  skipNull: true,
  arrayFormat: 'comma',
};

export function stringifyQueries(queries: StringifiableRecord): string {
  return queryString.stringify(queriesToSnakeCase(queries), stringifyOptions);
}

const parseOptions: ParseOptions = {
  arrayFormat: 'comma',
};

export function parseQueries(queries: string): ParsedQuery {
  return queriesToCamelCase(queryString.parse(queries, parseOptions));
}

export function parseQueriesFormUrl(url: string): ParsedQuery {
  return queriesToCamelCase(queryString.parseUrl(url, parseOptions).query);
}
