import React, { FC } from 'react';
import AuthLayout from '@modules/auth/components/layout/AuthLayout';
import * as AuthService from '@modules/auth/service';
import { forgotPasswordSchema } from '@modules/auth/model';
import ForgotPasswordForm from '@modules/auth/components/passwords/forgot/ForgotPasswordForm';
import Seo from '@shared/modules/seo/Seo';
import * as EI from 'fp-ts/Either';
import { defineRoute } from '@core/router';
import { defineAction, useAction } from '@core/router/action';
import { constFalse } from 'fp-ts/function';

const actions = {
  forgotPassword: defineAction({
    type: 'forgot',
    payload: forgotPasswordSchema,
    handler: ({ payload }) => AuthService.forgotPassword(payload),
    flashOptions: {
      error: constFalse,
      success: () => 'Un e-mail vous a été envoyé avec la procédure à suivre pour choisir un nouveau mot de passe.',
    },
    redirect: ({ result }) => (EI.isRight(result) ? '/login' : null),
  }),
};

const ForgotPasswordPage: FC = () => {
  const [loading, send, error] = useAction(actions.forgotPassword);

  return (
    <AuthLayout title="Mot de passe oublié">
      <Seo title="Mot de passe oublié" />
      <ForgotPasswordForm loading={loading} error={error} onSubmit={send} />
    </AuthLayout>
  );
};

const forgotPasswordRoute = defineRoute({
  component: ForgotPasswordPage,
  actions,
});

export default forgotPasswordRoute;
