import { defaultAxiosInstance } from '@core/http/config';
import { HttpError, HttpRange, HttpResult, HttpStatusCode, HttpTask } from '@core/http/model';
import { Filter } from '@shared/modules/filter';
import { RangeCursor, RangeResult } from '@shared/modules/range';
import { logSentryHttpError } from '@shared/modules/sentry/utils';
import { removeEmptyString } from '@shared/utils/string';
import { AxiosRequestConfig, AxiosResponse } from 'axios';
import { Lazy, pipe } from 'fp-ts/function';
import * as TE from 'fp-ts/TaskEither';
import * as EI from 'fp-ts/Either';
import * as T from 'fp-ts/Task';

import * as Retry from 'retry-ts';
import { retrying } from 'retry-ts/Task';
import { hideApiDownIndicator, showApiDownIndicator } from '@core/http/components/ApiDownIndicator';

function sendRequest<R, E>(request: Lazy<Promise<AxiosResponse<R>>>, raw?: true): HttpTask<R | AxiosResponse<R>, E> {
  const onError = (err: unknown, status: Retry.RetryStatus): HttpError<E> => {
    const error = HttpError.fromAxiosError<E>(err as any);

    error.log();

    if (
      status.iterNumber === 0 &&
      error.status >= 400 &&
      ![
        HttpStatusCode.UNAUTHORIZED,
        HttpStatusCode.FORBIDDEN,
        HttpStatusCode.NOT_FOUND,
        HttpStatusCode.CONFLICT,
      ].includes(error.status)
    ) {
      logSentryHttpError(`[http] error ${error.status} on ${error.url} path`, error);
    }

    if (error.isDownError()) {
      showApiDownIndicator();
    }

    return error;
  };

  const transformRequest = (status: Retry.RetryStatus) =>
    pipe(
      TE.tryCatch(request, err => onError(err, status)),
      TE.map(res => (raw ? res : res.data)),
    );

  const shouldRetry = (res: HttpResult) =>
    pipe(
      EI.swap(res),
      EI.exists(err => err.isDownError()),
    );

  return pipe(
    retrying(Retry.capDelay(2000, Retry.exponentialBackoff(500)), transformRequest, shouldRetry),
    T.chainFirstIOK(() => hideApiDownIndicator),
  );
}

function get<R = unknown, E = unknown>(url: string, config?: AxiosRequestConfig): HttpTask<R, E>;
function get<R = unknown, E = unknown>(
  url: string,
  config: AxiosRequestConfig,
  raw: true,
): HttpTask<AxiosResponse<R>, E>;

function get<R = unknown, E = unknown>(
  url: string,
  config?: AxiosRequestConfig,
  raw?: true,
): HttpTask<R | AxiosResponse<R>, E> {
  return sendRequest(() => defaultAxiosInstance.get(url, config), raw);
}

function getRange<R = unknown, F extends Filter = {}, E = unknown>(
  url: string,
  page: number,
  filter: F,
  config?: AxiosRequestConfig,
): HttpRange<R, F, E> {
  return pipe(
    get<RangeResult<R, F>, E>(url, {
      ...config,
      params: {
        ...config?.params,
        ...RangeCursor.fromPage(page),
        ...filter,
      },
    }),
    TE.map(res => ({
      ...res,
      filter,
    })),
  );
}

function removeEmptyStringOnBody(body?: any) {
  if (!(body instanceof FormData)) {
    return removeEmptyString(body);
  }

  return body;
}

function post<R = unknown, E = unknown>(url: string, data?: any, config?: AxiosRequestConfig): HttpTask<R, E>;
function post<R = unknown, E = unknown>(
  url: string,
  data: any,
  config: AxiosRequestConfig,
  raw: true,
): HttpTask<AxiosResponse<R>, E>;

function post<R = unknown, E = unknown>(
  url: string,
  data?: any,
  config?: AxiosRequestConfig,
  raw?: true,
): HttpTask<R | AxiosResponse<R>, E> {
  return sendRequest(() => defaultAxiosInstance.post(url, removeEmptyStringOnBody(data), config), raw);
}

function put<R = unknown, E = unknown>(url: string, data?: any, config?: AxiosRequestConfig): HttpTask<R, E>;
function put<R = unknown, E = unknown>(
  url: string,
  data: any,
  config: AxiosRequestConfig,
  raw: true,
): HttpTask<AxiosResponse<R>, E>;

function put<R = unknown, E = unknown>(
  url: string,
  data?: any,
  config?: AxiosRequestConfig,
  raw?: true,
): HttpTask<R | AxiosResponse<R>, E> {
  return sendRequest(() => defaultAxiosInstance.put(url, removeEmptyStringOnBody(data), config), raw);
}

function del<R = unknown, E = unknown>(url: string, config?: AxiosRequestConfig): HttpTask<R, E>;
function del<R = unknown, E = unknown>(
  url: string,
  config: AxiosRequestConfig,
  raw: true,
): HttpTask<AxiosResponse<R>, E>;

function del<R = unknown, E = unknown>(
  url: string,
  config?: AxiosRequestConfig,
  raw?: true,
): HttpTask<R | AxiosResponse<R>, E> {
  return sendRequest(() => defaultAxiosInstance.delete(url, config), raw);
}

export const httpService = {
  get,
  getRange,
  post,
  put,
  delete: del,
};
