import React, { FC } from 'react';
import { FilterChildrenProps } from '@shared/modules/filter';
import { CasesRangeFilter, CaseStatus, caseStatusLabel } from '@modules/cases/model';
import { getDropdownOptionsFromEnumLabels } from '@shared/utils/enum';
import { Select } from '@mantine/core';
import styled from '@emotion/styled';

const CasesFilterSelect = styled(Select)`
  margin-right: 16px;

  @media screen and (max-width: 760px) {
    margin-right: 0;
    margin-bottom: 10px;
    width: 100%;
  }
`;

const CasesFilter: FC<FilterChildrenProps<CasesRangeFilter>> = ({ filter, onChange }) => {
  const handleChange = (value: CaseStatus | null) => {
    onChange({
      ...filter,
      status: value,
    });
  };

  return (
    <CasesFilterSelect
      placeholder="Statut"
      data={getDropdownOptionsFromEnumLabels(caseStatusLabel)}
      value={filter.status}
      onChange={handleChange}
      clearable
      mr="md"
    />
  );
};

export default CasesFilter;
