import React, { FC } from 'react';
import * as CaseService from './service';
import { defineRoute } from '@core/router';
import { defineAction, useAction } from '@core/router/action';
import { CaseProducts, CreateCaseFormBody, createCaseSchema, createCaseSchemaParams } from '@modules/cases/model';
import { HttpStatusCode } from '@core/http';
import Page, { PageProps } from '@layout/page/Page';
import { pipe } from 'fp-ts/function';
import { EnhancedForm, mapToEnhancedFormSubmitResult, useEnhancedForm } from '@shared/modules/form';
import { zodResolver } from '@hookform/resolvers/zod';
import CreateForm from '@modules/cases/components/form/CreateForm';
import { defineLoader, httpTaskToResponseTask, useLoader } from '@core/router/loader';
import { DateFormat, formatDate } from '@shared/modules/dates';

const loader = defineLoader({
  handler: () => {
    return httpTaskToResponseTask(CaseService.getProducts());
  },
});

const actions = {
  create: defineAction({
    type: 'create',
    payload: createCaseSchemaParams,
    handler: ({ payload }) => CaseService.createCase(payload),
    flashOptions: {
      success: () => 'Chantier créé avec succès',
      error: ({ error }) => (error.status === HttpStatusCode.CONFLICT ? 'Ce nom de chantier existe déjà' : true),
    },
  }),
};

function getDefaultValues(products: Array<CaseProducts>): CreateCaseFormBody {
  return {
    name: '',
    customerName: '',
    address: {
      city: '',
      postalCode: '',
      firstLine: '',
    },
    installDate: null,
    type: null,
    zoneInfo: null,
    products: products.map(product => ({
      productId: product.id,
      label: product.label,
      qte: null,
    })),
  };
}

const CreateCasePage: FC = () => {
  const products = useLoader<typeof loader>();

  const { formRef, form, handleFormSubmit } = useEnhancedForm({
    resolver: zodResolver(createCaseSchema),
    defaultValues: getDefaultValues(products),
  });

  const [loading, create] = useAction(actions.create);

  const pageProps: PageProps = {
    title: 'Nouveau chantier',
  };

  const handleCreateCase = (params: CreateCaseFormBody) =>
    pipe(
      () =>
        create({
          ...params,
          installDate: params.installDate
            ? formatDate(params.installDate, DateFormat.LocalDate)
            : formatDate(new Date(), DateFormat.LocalDate),
          zoneInfo: {
            buildingSurface: params.zoneInfo?.buildingSurface ?? 0,
            landSurface: params.zoneInfo?.landSurface ?? 0,
          },
        }),
      mapToEnhancedFormSubmitResult(c => `cases/${c.id}`),
    );

  return (
    <Page {...pageProps}>
      <EnhancedForm ref={formRef} form={form} onSubmit={handleCreateCase} preventLeave>
        <CreateForm loading={loading} onSubmit={handleFormSubmit} />
      </EnhancedForm>
    </Page>
  );
};

const createCaseRoute = defineRoute({
  component: CreateCasePage,
  loader,
  actions,
});

export default createCaseRoute;
