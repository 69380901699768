import styled from '@emotion/styled';
import { Button, ButtonProps, createPolymorphicComponent } from '@mantine/core';

export const PageContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const PageTopContainer = styled.div`
  background-color: rgba(0, 0, 0, 0.87);
  color: ${props => props.theme.white};
`;

export const PageTop = styled.div`
  margin: 0 auto;
  display: flex;
  align-items: center;
  padding: 5px 30px;
  width: 100%;
  max-width: 1280px;
`;

export const PageTopLeft = styled.div`
  flex: 1 1 auto;

  @media screen and (max-width: 760px) {
    h1 {
      font-size: 22px;
    }
  }
`;

export const PageTopRight = styled.div`
  flex: 1 1 auto;
`;

export const PageTopRightButton = createPolymorphicComponent<'button', ButtonProps>(styled(Button)`
  margin-top: 0;

  @media screen and (max-width: 760px) {
    padding: 0 10px;

    p {
      display: none;
    }

    span:first-of-type {
      margin-right: 0;
    }
  }
`);

export const PageContentWrapper = styled.div`
  flex: 1 1 auto;
  overflow-y: scroll;
`;

export const PageContent = styled.div`
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  max-width: 1280px;
  padding: 20px;
`;

export const PageChildren = styled.div`
  flex: 1 1 auto;
  padding-bottom: 20px;
`;

export const PageBottom = styled.div`
  min-height: 61px;
  background: ${props => props.theme.white};
  border-top: 1px solid ${props => props.theme.colors.gray[2]};

  > div {
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    margin: 0 auto;
    padding: 0 15px;

    > div {
      display: flex;
      flex: 1 1 auto;
      align-items: center;
      padding: 7px 0;

      &.left {
        justify-content: flex-start;
      }

      &.right {
        justify-content: flex-end;
      }
    }
  }
`;
