import { Action, EmptyAction, FormDataAction, PayloadAction } from './index';
import { HttpError, HttpRemoteData, HttpResult, HttpTask } from '@core/http';
import * as O from 'fp-ts/Option';
import * as EI from 'fp-ts/Either';
import * as RD from 'fp-ts-remote-data';
import * as T from 'fp-ts/Task';
import { useFetcher } from 'react-router-dom';
import { pipe } from 'fp-ts/function';
import { Dispatch, SetStateAction, useCallback, useEffect, useRef, useState } from 'react';
import { z } from 'zod';

export function useAction<
  Action extends PayloadAction<any, any, any, any>,
  PayloadSchema = Action extends PayloadAction<infer Payload, any, any, any> ? Payload : never,
  Payload = PayloadSchema extends z.ZodType ? z.infer<PayloadSchema> : never,
  R = Action extends PayloadAction<any, any, infer R, any> ? R : never,
  E = Action extends PayloadAction<any, any, any, infer E> ? E : never,
>(
  action: Action,
  actionUrl?: string,
): [boolean, (payload: Payload) => Promise<HttpResult<R, E>>, O.Option<HttpError<E>>, boolean];

export function useAction<
  Action extends FormDataAction<any, any, any>,
  R = Action extends FormDataAction<any, infer R, any> ? R : never,
  E = Action extends FormDataAction<any, any, infer E> ? E : never,
>(
  action: Action,
  actionUrl?: string,
): [boolean, (formData: FormData) => Promise<HttpResult<R, E>>, O.Option<HttpError<E>>, boolean];

export function useAction<
  Action extends EmptyAction<any, any, any>,
  R = Action extends EmptyAction<any, infer R, any> ? R : never,
  E = Action extends EmptyAction<any, any, infer E> ? E : never,
>(action: Action, actionUrl?: string): [boolean, () => Promise<HttpResult<R, E>>, O.Option<HttpError<E>>, boolean];

/**
 * Hooks permettant d'appeler une action
 *
 * @param action - action que l'on veut appeller
 * @param actionUrl - url de l'action, à utiliser si l'action se trouve sur une autre route
 */
export function useAction(
  action: Action,
  actionUrl?: string,
): [boolean, (payload: unknown) => Promise<HttpResult>, O.Option<HttpError>, boolean] {
  const fetcher = useFetcher<HttpResult>();

  const fetcherRef = useRef(fetcher);

  fetcherRef.current = fetcher;

  const loading = fetcher.state !== 'idle';

  const error = pipe(
    O.fromNullable(fetcher.data),
    O.chain(data => O.fromEither(EI.swap(data))),
  );

  const success = pipe(O.fromNullable(fetcher.data), O.exists(EI.isRight));

  const handleAction = useCallback(
    (payload: unknown) => {
      const formData = createActionFormData(action, payload);

      fetcherRef.current.submit(formData, { method: 'post', action: actionUrl });

      return new Promise<HttpResult>((resolve, reject) => {
        let interval: NodeJS.Timer;

        let hasRun = false;

        /**
         * Check toutes les 5 secondes si l'état du fetcher change
         */
        const waitForResult = () => {
          if (!hasRun) {
            hasRun = fetcherRef.current.state !== 'idle';
          } else if (fetcherRef.current.state === 'idle') {
            const res = fetcherRef.current.data;

            if (res) {
              resolve(res);
            } else {
              reject(new Error('No data return from action'));
            }

            clearInterval(interval);
          }
        };

        interval = setInterval(waitForResult, 5);

        waitForResult();
      });
    },
    [action, actionUrl],
  );

  return [loading, handleAction, error, success];
}

/**
 * Utilise une action pour récupérer ses données
 *
 * @param action - action que l'on veut appeler
 * @param payload - payload de l'action
 * @param actionUrl - url de l'action si l'action se trouve sur une autre route
 */
export function useActionLoader<
  Action extends PayloadAction<any, any, any, any>,
  PayloadSchema = Action extends PayloadAction<infer Payload, any, any, any> ? Payload : never,
  Payload = PayloadSchema extends z.ZodType ? z.infer<PayloadSchema> : never,
  R = Action extends PayloadAction<any, any, infer R, any> ? R : never,
  E = Action extends PayloadAction<any, any, any, infer E> ? E : never,
>(
  action: Action,
  payload: Payload,
  actionUrl?: string,
): [HttpRemoteData<R, E>, HttpTask<R, E>, boolean, Dispatch<SetStateAction<HttpRemoteData<R, E>>>];

/**
 * tilise une action pour récupérer ses données
 *
 * @param action - action que l'on veut appeler
 * @param actionUrl - url de l'action si l'action se trouve sur une autre route
 */
export function useActionLoader<
  Action extends EmptyAction<any, any, any>,
  R = Action extends EmptyAction<any, infer R, any> ? R : never,
  E = Action extends EmptyAction<any, any, infer E> ? E : never,
>(
  action: Action,
  actionUrl?: string,
): [HttpRemoteData<R, E>, HttpTask<R, E>, boolean, Dispatch<SetStateAction<HttpRemoteData<R, E>>>];

export function useActionLoader(
  action: Action,
  payloadOrActionUrl?: any,
  actionUrl?: string,
): [HttpRemoteData, HttpTask, boolean, Dispatch<SetStateAction<HttpRemoteData>>] {
  const [data, setData] = useState<HttpRemoteData>(RD.pending);

  const isPayloadAction = 'payload' in action;

  const computedActionUrl = isPayloadAction ? actionUrl : payloadOrActionUrl;

  const [loading, fetch] = useAction(action as any, computedActionUrl);

  const fetchTask = useCallback(() => {
    const payload = isPayloadAction ? payloadOrActionUrl : undefined;

    return pipe(
      () => fetch(payload),
      T.chainFirstIOK(res => () => setData(RD.fromEither(res))),
    )();
  }, [fetch, isPayloadAction, payloadOrActionUrl]);

  useEffect(() => {
    fetchTask();
  }, [fetchTask]);

  return [data, fetchTask, loading, setData];
}

/**
 * Créé le FormData suivant de type de l'action
 *
 * @param action
 * @param payload
 */
function createActionFormData(action: Action, payload?: unknown) {
  if ('formData' in action) {
    if (payload instanceof FormData) {
      payload.set('_type', action.type);

      return payload;
    }

    throw new Error('No FormData argument for FormData action');
  }

  if ('payload' in action) {
    if (payload) {
      const formData = new FormData();

      formData.set('_type', action.type);
      formData.set('payload', JSON.stringify(payload));

      return formData;
    }

    throw new Error('No payload argument for payload action');
  }

  const formData = new FormData();

  formData.set('_type', action.type);

  return formData;
}
