import React, { FC } from 'react';
import * as CaseService from './service';
import * as O from 'fp-ts/Option';
import * as EI from 'fp-ts/Either';
import { defineRoute } from '@core/router';
import { defineLoader, httpTaskToResponseTask, useLoader } from '@core/router/loader';
import { createNewTypeStringSchema } from '@shared/schemas';
import { CaseId, DetailCase, UpdateCaseFormBody, updateCaseSchema, updateCaseSchemaParams } from '@modules/cases/model';
import z from 'zod';
import { defineAction, useAction } from '@core/router/action';
import Page, { PageProps } from '@layout/page/Page';
import { Group } from '@mantine/core';
import { IconArrowLeft } from '@tabler/icons-react';
import { Link } from 'react-router-dom';
import { EnhancedForm, mapToEnhancedFormSubmitResult, useEnhancedForm } from '@shared/modules/form';
import UpdateForm from '@modules/cases/components/form/UpdateForm';
import { zodResolver } from '@hookform/resolvers/zod';
import { pipe } from 'fp-ts/function';
import { DateFormat, formatDate, parseDate } from '@shared/modules/dates';

const caseIdSchema = z.object({ id: createNewTypeStringSchema<CaseId>() });

const loader = defineLoader({
  params: caseIdSchema,
  handler: ({ params }) => httpTaskToResponseTask(CaseService.getCaseDetail(params.id)),
});

function getDefaultValues(item: DetailCase): UpdateCaseFormBody {
  return {
    name: item.name,
    customerName: item.customerName,
    address: item.address,
    installDate: O.toNullable(parseDate(item.installDate, DateFormat.LocalDate)) ?? new Date(),
    terminationDate: O.toNullable(parseDate(item.terminationDate, DateFormat.LocalDate)) ?? null,
    zoneInfo: item.zoneInfo,
    products: item.products,
  };
}

const actions = {
  update: defineAction({
    type: 'update',
    params: caseIdSchema,
    payload: updateCaseSchemaParams,
    handler: ({ payload, params }) => CaseService.updateCase(params.id, payload),
    flashOptions: {
      success: () => 'Chantier modifié avec succès',
    },
  }),
  delete: defineAction({
    type: 'delete',
    params: caseIdSchema,
    handler: ({ params }) => CaseService.deleteCase(params.id),
    flashOptions: {
      success: () => 'Chantier supprimé avec succès',
    },
    redirect: ({ result }) => (EI.isRight(result) ? '/' : null),
  }),
};

const CaseDetailPage: FC = () => {
  const caseDetail = useLoader<typeof loader>();

  const { formRef, form, handleFormSubmit } = useEnhancedForm({
    resolver: zodResolver(updateCaseSchema),
    defaultValues: getDefaultValues(caseDetail),
  });

  const [loading, update] = useAction(actions.update);

  const [deleteLoading, deleteCase] = useAction(actions.delete);

  const pageProps: PageProps = {
    title: (
      <Group spacing="sm">
        <Link to="/" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <IconArrowLeft color="white" />
        </Link>

        {caseDetail.name}
      </Group>
    ),
  };

  const handleUpdateCase = (params: UpdateCaseFormBody) => {
    return pipe(
      () =>
        update({
          ...params,
          installDate: formatDate(params.installDate, DateFormat.LocalDate),
          terminationDate: formatDate(params.terminationDate, DateFormat.LocalDate),
        }),
      mapToEnhancedFormSubmitResult(),
    );
  };

  return (
    <Page {...pageProps}>
      <EnhancedForm ref={formRef} form={form} onSubmit={handleUpdateCase} preventLeave>
        <UpdateForm loading={loading} onSubmit={handleFormSubmit} deleteLoading={deleteLoading} onDelete={deleteCase} />
      </EnhancedForm>
    </Page>
  );
};

const caseDetailRoute = defineRoute({
  component: CaseDetailPage,
  loader,
  actions,
});

export default caseDetailRoute;
