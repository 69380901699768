import React, { FC, useEffect } from 'react';
import {
  createBrowserRouter,
  createRoutesFromElements,
  Navigate,
  Outlet,
  Route,
  useNavigation,
} from 'react-router-dom';

import { createRoute } from '@core/router';
import Layout from '@layout/Layout';

import { resetNavigationProgress, startNavigationProgress } from '@mantine/nprogress';
import ErrorPage from '@shared/components/error-page/ErrorPage';
import loginRoute from '@modules/auth/LoginPage';
import profilePageRoute from '@modules/auth/ProfilePage';
import forgotPasswordRoute from '@modules/auth/ForgotPasswordPage';
import activationRoute from '@modules/auth/ActivationPage';
import resetPasswordRoute from '@modules/auth/ResetPasswordPage';

import casesRoutes from '@modules/cases/routes';
import importRoutes from '@modules/import/routes';
import stocksRoutes from '@modules/stocks/routes';
import profileLoaderRoute, { useProfile } from '@modules/auth/profile-loader';
import logoutRoute from '@modules/auth/logout';
import { Profile, ProfileAccess } from '@modules/auth/model';

function getRedirection(profile: Profile) {
  switch (profile.access) {
    case ProfileAccess.Basic:
      return '/cases';
    case ProfileAccess.Import:
      return '/import';
  }

  return '/cases';
}

const NavigationLoader: FC = () => {
  const navigation = useNavigation();

  useEffect(() => {
    if (navigation.state !== 'idle') {
      startNavigationProgress();
    } else {
      resetNavigationProgress();
    }
  }, [navigation.state]);

  return <Outlet />;
};

const HomeRedirection: FC = () => {
  const profile = useProfile();

  const redirect = getRedirection(profile);

  return <Navigate to={redirect} replace />;
};

const AccessGuard: FC<{ access: ProfileAccess }> = ({ access }) => {
  const profile = useProfile();

  if (profile.access === access) {
    return <Outlet />;
  } else {
    return <Navigate to={getRedirection(profile)} replace />;
  }
};

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route element={<NavigationLoader />}>
      <Route path="/login" {...createRoute(loginRoute)} />

      <Route path="/password-reset/forgot" {...createRoute(forgotPasswordRoute)} />
      <Route path="/password-reset/:token" {...createRoute(resetPasswordRoute)} />

      <Route path="/activation/:token" {...createRoute(activationRoute)} />

      <Route {...createRoute(profileLoaderRoute)}>
        <Route element={<Layout />}>
          <Route index element={<HomeRedirection />} />

          <Route path="/profile" {...createRoute(profilePageRoute)} />

          <Route element={<AccessGuard access={ProfileAccess.Import} />}>{importRoutes}</Route>

          <Route element={<AccessGuard access={ProfileAccess.Basic} />}>{casesRoutes}</Route>

          {stocksRoutes}

          <Route path="/logout" {...createRoute(logoutRoute)} />

          <Route path="*" element={<ErrorPage />} />
        </Route>

        <Route path="*" element={<ErrorPage />} />
      </Route>
    </Route>,
  ),
);

export default router;
