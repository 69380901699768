import React, { FC } from 'react';
import { Button, Text, Group, Paper } from '@mantine/core';
import { Link } from 'react-router-dom';
import { IconHome } from '@tabler/icons-react';

const ImportFormSaved: FC = () => {
  return (
    <>
      <Paper style={{ border: '2px solid green' }} mt="md" p="xl">
        <Text weight={700} color="green">
          Votre fichier a bien été importé
        </Text>

        <Text>L'équipe Liphatech vous remercie</Text>
      </Paper>

      <Group position="right" mt="md">
        <Button component={Link} to="/">
          <IconHome />
          <Text>Retour à l'accueil</Text>
        </Button>
      </Group>
    </>
  );
};

export default ImportFormSaved;
